<template>
  <div class="row"> 
    <b-modal 
      id="upload-new-waybill-image"
      ref="upload-new-waybill-image"
      hide-header
      hide-header-close
      scrollable
      size="lg"
      style="z-index:1051 !important;"
    >
      <div class="card-body card-body-scroll">
          <photo-recorder
              :model.sync="document"></photo-recorder>
          <file-input
              ref="imageFileInput"
              :model.sync="document"
              :title="$t('editSample.image')"
              accept="image/*"
              name="content-image"
              @fileChange="onDocumentChange"
          >
            <template v-slot:inputDesign>
              <div class="w-100 d-flex justify-content-center" @click="$refs.imageFileInput.openFileInput()">
                <span v-html="getIconByKey('icons.meeting.select-from-file', {
                      class: 'w-75px h-75px d-inline-block object-fill cursor-pointer'
                })">
                </span>
              </div>
            </template>
          </file-input>
        </div>
       <template #modal-footer>
          <button class="btn btn-outline-danger font-weight-bold cursor-pointer mr-3 btn-pill"
                  type="button"
                  @click="cancelDocumentUpload">
            {{ $t('editSample.close') }}
          </button>
          <button class="btn btn-sm btn-primary font-weight-bold cursor-pointer btn-pill"
                  type="button"
                  @click="closeUploadDocumentModal">{{ $t('editSample.save') }}
          </button>
      </template>    
    </b-modal>
    <b-modal 
      id="display-detail-modal"
      ref="display-detail-modal"
      hide-header
      hide-header-close
      scrollable
      size="xl"
      style="z-index:3"
    >
      <div class="row mx-0 ">
        <div class="col-12 mx-0 py-2" v-if="canCheckSupplierScope || canCheckAdminScope || canCheckCustomRolesScope ">
          <div class="top_header">Tedarikçi Kapama Raporu</div>
            <table class="table backgrid navy-blue-border-1px pt-3 px-0 border-15px">
                <thead class="">
                <tr> 
                  <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                      Sipariş Adeti
                  </th> 
                    <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                      Renk  
                    </th> 
                  <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                      Kesim Adeti
                  </th> 
                  <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                    Yüklenen Adet
                  </th> 
                    <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                    Numune
                  </th> 
                    <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                    İkinci Kalite
                  </th> 
                  <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                    Parça İade
                  </th> 
                  <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                    Toplam
                  </th> 
                </tr>
                </thead>
                <tbody> 
                  <tr > 
                    <td class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >{{ orderAmount }}</td>
                    <td class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >{{ item.order_size_and_color.full_name }}</td> 
                    <td class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >
                      <div class="" v-if=" actualAmountCut >  0 "> 
                        {{actualAmountCut}}                        
                      </div>
                      <div v-else>
                        <input   
                          type="number" :placeholder="$t('following.please_enter_manually')" class="form-control form-control-xs" 
                          v-model="secondQualityInputs.total_cut">  
                      </div>
                    </td>
                    <td
                      class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >
                      <input   :disabled="isSupplierEditFormData(secondQualityInputs.supplier_created_at)"
                      type="number" :placeholder="$t('following.please_enter_manually')" class="form-control form-control-xs" 
                      v-model="secondQualityInputs.loaded_quantity_supplier"> 
                    </td>
                      <td
                      class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >
                      <input   :disabled="isSupplierEditFormData(secondQualityInputs.supplier_created_at)"
                      type="number" :placeholder="$t('following.please_enter_manually')" class="form-control form-control-xs" 
                      v-model="secondQualityInputs.sample_amount_supplier"> 
                    </td>
                    <td
                      class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >
                      <input   :disabled="isSupplierEditFormData(secondQualityInputs.supplier_created_at)"
                      type="number" :placeholder="$t('following.please_enter_manually')" class="form-control form-control-xs" 
                      v-model="secondQualityInputs.second_quantity_supplier"> 
                    </td>
                    <td
                      class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >
                      <input   :disabled="isSupplierEditFormData(secondQualityInputs.supplier_created_at)"
                      type="number" :placeholder="$t('following.please_enter_manually')" class="form-control form-control-xs" 
                      v-model="secondQualityInputs.part_return_supplier"> 
                    </td>
                    <td class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >{{enteredTotal}}</td>
                  </tr>
                </tbody> 
            </table>
            <div class="d-flex justify-content-end items-center">
                <img v-show="document"
                     id="following_document" :src="document"
                     alt=""
                     class="w-50px h-50px object-cover border-15px navy-blue-border-1px mr-5">
                <span
                    class="px-3 py-1 navy-blue-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm"
                    @click="openUploadDocumentModal">
                  <span
                      v-html="getIconByKey('icons.waybill.add_document', { class: 'w-20px h-20px object-cover d-inline-block opacity-75 mr-2'})"></span>
                  {{ $t('waybill.upload_document').toUpperCase() }}
                </span>
            </div>
        </div> 
          <div class="col-12 mx-0 py-2" v-if="canCheckAdminScope || canCheckCustomRolesScope">
          <div class="top_header">Pulpopro Kapama Raporu</div>
            <table class="table backgrid navy-blue-border-1px pt-3 px-0 border-15px">
                <thead class="">
                <tr> 
                  <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                      Sipariş Adeti
                  </th> 
                    <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                      Renk  
                    </th> 
                  <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                      Kesim Adeti
                  </th> 
                  <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                    Yüklenen Adet
                  </th> 
                    <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                    Numune
                  </th> 
                    <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                    İkinci Kalite
                  </th> 
                  <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                    Parça İade
                  </th> 
                  <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                    Toplam
                  </th> 
                </tr>
                </thead>
                <tbody> 
                  <tr > 
                    <td class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >{{ orderAmount }}</td>
                    <td class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >{{ item.order_size_and_color.full_name }}</td> 
                    <td class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >                      
                      <div class="" v-if=" actualAmountCut >  0 "> 
                        {{actualAmountCut}}                        
                      </div>
                      <div v-else>
                        <input   
                          type="number" :placeholder="$t('following.please_enter_manually')" class="form-control form-control-xs" 
                          v-model="secondQualityInputs.total_cut">  
                      </div>
                    </td>
                    <td
                      class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >
                      <input   
                      type="number" :placeholder="$t('following.please_enter_manually')" class="form-control form-control-xs" 
                      :class="{'is-invalid': Number(secondQualityInputs.loaded_quantity_manager) < Number(secondQualityInputs.loaded_quantity_supplier)}"
                      v-model="secondQualityInputs.loaded_quantity_manager"> 
                    </td>
                    <td
                      class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >
                      <input   
                      type="number" :placeholder="$t('following.please_enter_manually')" class="form-control form-control-xs"  
                      :class="{'is-invalid': Number(secondQualityInputs.sample_amount_manager) < Number(secondQualityInputs.sample_amount_supplier)}"
                      v-model="secondQualityInputs.sample_amount_manager"> 
                    </td>
                    <td
                      class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >
                      <input   
                      type="number" :placeholder="$t('following.please_enter_manually')" class="form-control form-control-xs" 
                      :class="{'is-invalid': Number(secondQualityInputs.second_quantity_manager) < Number(secondQualityInputs.second_quantity_supplier)}"
                      v-model="secondQualityInputs.second_quantity_manager"> 
                    </td>
                    <td
                      class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >
                      <input   
                      type="number" :placeholder="$t('following.please_enter_manually')" class="form-control form-control-xs" 
                      :class="{'is-invalid': Number(secondQualityInputs.part_return_manager) < Number(secondQualityInputs.part_return_supplier)}"
                      v-model="secondQualityInputs.part_return_manager"> 
                    </td>
                    <td class="navy-blue-border-bottom-1px navy-blue-border-right-1px" >{{enteredTotalAdmin}}</td>
                  </tr>
                </tbody> 
            </table>
        </div> 
      </div>
      <template #modal-footer>
        <div class="col-12 text-center">
          <button
            class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5"
            @click="closeModal('display-detail-modal')"
          >
            {{ $t("general.close") }}
          </button>
            <button
            class="btn btn-sm btn-outline-success p-2 btn-pill font-weight-bold cursor-pointer mr-5 mx-10 w-25"
            @click="saveSecondQuantity"
          >
            {{ $t("general.save") }}
          </button>
        </div>
      </template>
    </b-modal>
    <div class="col-md-12 d-flex justify-content-start items-center">
     <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)">
       <i class="fa fa-chevron-left"></i>
     </span>
      <h3>{{ $t('following.production_plan')}}</h3>
    </div>
    <div class="col-md-12">
      <dashboard-box>
        <template v-slot:preview>
          <div class="px-5 py-3" v-if="item && type">
            <div class="d-flex mx-0 items-start">
              <div class="w-75px h-75px border-15px">
                <img :src="item.order_expense.order.image_link" alt=""
                     class="w-75px h-75px border-15px object-cover primary-border-2px">
              </div>
              <div class="flex-grow-1 row mx-0 mx-2">
                <div class="row w-100 py-3 mx-0 text-lg">
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <span class="font-weight-bold">{{ $t('following.instruction_date') }} : </span>
                    <span class=""> {{ item.date | laravelDateToSystem }}</span>
                  </div>

                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <span class="font-weight-bold">{{ $t('following.dispatch_location') }} : </span>
                    <span class=""> {{ item.dispatch_locations.length > 0 ? item.dispatch_locations.join(',') : '-' }}</span>
                  </div>

                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <span class="font-weight-bold">{{ $t('following.order_number') }} : </span>
                    <span class=""> {{ item.order_expense.order.order_number }}</span>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <span class="font-weight-bold">{{ $t('following.instruction_number') }} : </span>
                    <span class=""> TN{{ item.qr_code }}</span>
                  </div>

                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <span class="font-weight-bold">{{ $t('following.instruction_type') }} : </span>
                    <span class=""> {{ item.order_expense.expense.translations[0].name }}</span>
                  </div>

                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <span class="font-weight-bold">{{ $t('following.start_date') }} : </span>
                    <span class=""> {{ item.start_date | laravelDateToSystem }}</span>
                  </div>

                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <span class="font-weight-bold">{{ $t('following.end_date') }} : </span>
                    <span class=""> {{ item.end_date | laravelDateToSystem }}</span>
                  </div>
                   <div class="col-lg-3 col-md-4 col-sm-6 text-center" >
                    <span v-if="canCheckSupplierScope || canCheckAdminScope || canCheckCustomRolesScope" @click=" openEntryDataModalTable" v-html="getIconByKey('icons.waybill.waybill_entry', {class: 'w-60px h-60px d-inline-block object-fill cursor-pointer'
                      })"></span> 
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-2 row mx-0" v-if="type === 1">
              <div class="col-12 text-center text-3xl font-weight-boldest mb-3">{{ $t('following.production_plan').toUpperCase() }} {{item.order_size_and_color?'/ '+item.order_size_and_color.full_name:''}}
              </div>
              <div class="col-12 row mx-0 navy-blue-border-1px p-3 border-15px">
                <div class="col-12 d-flex navy-blue-border-bottom-1px">
                  <div class="w-200px h-35px align-content-center text-lg">{{ $t('following.order_amount') }}</div>
                  <div class="flex-grow-1 h-35px text-2xl text-primary text-center font-weight-boldest ">
                    {{ orderAmount }}
                  </div>
                  <span v-if="false" class="w-200px cursor-pointer d-flex justify-content-end" v-html="getIconByKey('icons.waybill.waybill_entry', {
                  class: 'w-30px h-30px object-fill d-inline-block'
                })"></span>
                </div>
                <div class="col-12 d-flex pt-2">
                  <div class="w-200px h-35px align-content-center text-lg">{{ $t('following.actual') }}</div>
                  <div class="flex-grow-1 h-35px text-2xl text-center font-weight-boldest ">
                    {{ actualAmount }}
                  </div>
                  <div class="w-200px"></div>
                </div>
              </div>
            </div>

            <div class="mt-2 row mx-0" v-if="type === 2">
              <div class="col-12 text-center text-3xl font-weight-boldest mb-3">{{ $t('following.production_plan').toUpperCase() }} {{item.order_size_and_color?'/ '+item.order_size_and_color.full_name:''}}
              </div>
              <div class="col-12 row mx-0 navy-blue-border-1px p-3 border-15px">
                <div
                    class="col-12 d-flex justify-content-center items-center font-weight-boldest navy-blue-border-bottom-1px pb-3">
                  <span class="text-2xl text-primary d-inline-block px-3">{{ $t('following.order_amount') }}</span>
                  <span class="text-3xl text-primary d-inline-block px-3">{{ orderAmount }}</span>
                  <span class="text-3xl d-inline-block px-3">/</span>
                  <span class="text-3xl d-inline-block px-3">{{ actualAmount }}</span>
                  <span class="text-2xl d-inline-block px-3">{{ $t('following.actual') }}</span>
                </div>
                <div class="col-12 table-responsive px-0" style="overflow-scrolling: auto;">
                  <table class="table backgrid">
                    <thead>
                    <tr>
                      <th colspan="2"
                          class="navy-blue-border-bottom-1px navy-blue-border-right-1px"
                          :class="{'navy-blue-border-left-1px': index === 0} "
                          v-for="(target, index) in item.order_expense_detail_targets">
                        <div class="d-flex justify-content-between items-center ">
                          <p class="text-center text-xl font-weight-bold mb-0 flex-grow-1">
                            <template v-if="Number(target.id) !== -1">
                              {{ target.date | laravelDateToSystem }}
                            </template>
                            <template v-else>
                                <date-picker-input
                                  :required="false"
                                  :title="null"
                                  :name="`target_date_${index}`"
                                  :model.sync="target.date"
                                ></date-picker-input>
                            </template>
                          </p>
                          <span v-if="false && index === item.order_expense_detail_targets.length - 1 && false"
                                @click="addNewTarget"
                                class="w-35px cursor-pointer float-right" v-html="getIconByKey('icons.waybill.waybill_entry', {
                            class: 'w-30px h-30px object-fill d-inline-block'
                          })">
                          </span>
                        </div>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="">
                      <template v-for="(target, index) in item.order_expense_detail_targets">
                        <td class="text-lg text-center navy-blue-border-bottom-1px navy-blue-border-right-1px"
                            :class="{'navy-blue-border-left-1px': index === 0}"
                        >{{ $t('following.target') }}
                        </td>
                        <td class="text-lg text-center navy-blue-border-bottom-1px navy-blue-border-right-1px">
                          {{ $t('following.actual') }}
                        </td>
                      </template>
                    </tr>
                    <tr>
                      <template v-for="(target, index) in item.order_expense_detail_targets">
                        <td class="text-lg text-center navy-blue-border-bottom-1px navy-blue-border-right-1px"
                            :class="{'navy-blue-border-left-1px': index === 0}"
                        >
                          <template v-if="Number(target.id) !== -1">
                          {{ target.target }}
                          </template>
                          <template v-else>
                            <div class="row">
                              <div class="col-6">
                                <input type="number" v-model="target.target" step="1" :placeholder="$t('following.please_enter_manually')" class="form-control form-control-xs">
                              </div>
                              <div v-if="false" class="col-6 py-1 px-1" style="max-width: 100px;">
                                <button-with-icon
                                    @onClick="saveNewTarget(index)"
                                    :disabled="Number(target.target) === 0 && is_submitting"
                                    :text="$t('following.save')"
                                    :icon-name="null"
                                    size="sm"
                                ></button-with-icon>
                              </div>
                            </div>
                          </template>
                        </td>
                        <td class="text-lg text-center navy-blue-border-bottom-1px navy-blue-border-right-1px"
                            :class="{'navy-blue-border-left-1px': index === 0}"
                        >{{ target.actual }}
                        </td>
                      </template>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="mt-2 row mx-0">
              <div class="col-12 text-center text-3xl font-weight-boldest">
                {{ $t('following.production_entries').toUpperCase() }}
                <span v-if="false && canAddInput && isUserGranted('Staff', ['supplierCompanyStaff'], false)"
                      @click="addNewInput"
                      class="cursor-pointer float-right" v-html="getIconByKey('icons.waybill.waybill_entry', {
                  class: 'w-30px h-30px object-fill d-inline-block'
                })"></span>
              </div>
              <div class="col-12 row mx-0 navy-blue-border-1px pt-3 px-0 border-15px">
                <div class="col-12 row mx-0 navy-blue-border-bottom-1px">
                  <div class="col-4 text-lg font-weight-boldest py-2 text-center navy-blue-border-right-1px">{{ $t('following.date') }}</div>
                  <div class="col-4 text-lg font-weight-boldest py-2 text-center navy-blue-border-right-1px">Süre</div>
                  <div class="col-4 text-lg font-weight-boldest py-2 text-center">{{ $t('following.actual') }}</div>
                </div>
                <div class="col-12 row mx-0" v-for="(input, index) in targetInputs"
                  :class="{'navy-blue-border-bottom-1px': index !== item.order_expense_detail_targets.length - 1}"
                >
                  <div class="col-4 text-lg text-center py-1 navy-blue-border-right-1px">{{ input.date  | laravelDateToSystem }}</div>
                  <div class="col-4 text-lg text-center py-1 navy-blue-border-right-1px">
                    {{isSameDateWhatOclok(input.date)}}
                  </div>
                  <div class="col-4 text-lg text-center py-1 d-flex justify-content-center">
                    <template v-if="canCheckInsertScope||((Number(input.id) === -1 || isSameDate(input.date)) && !item.order_expense.expense.suppliers_can_enter_body_sizes)">
                      <div class="row w-200px">
                        <div class="col-8">
                          <input type="number" step="1" v-model="input.amount" :placeholder="$t('following.please_enter_manually')" class="form-control form-control-sm">
                        </div>
                        <div class="col-4 px-1 py-1">
                          <button-with-icon v-if="!item.order_expense.expense.suppliers_can_enter_body_sizes"
                              @onClick="saveOrUpdateInput(index)"
                            :text="Number(input.id) === -1 ? $t('following.save') : $t('following.update')"
                            :icon-name="null"
                            size="sm"
                            :disabled="Number(input.amount) === 0 && is_submitting"
                          ></button-with-icon>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      {{ input.amount }}
                    </template>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="mt-2 row mx-0" v-if="item.order_expense.expense.suppliers_can_enter_body_sizes">
              <div class="col-12 text-center text-3xl font-weight-boldest">
                {{ $t('following.production_entries_by_size').toUpperCase() }}
              </div>
              <table class="table backgrid navy-blue-border-1px pt-3 px-0 border-15px">
                    <thead class="">
                    <tr>
                      <th class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                        {{ $t('following.date') }}
                      </th>
                      <th
                          class="navy-blue-border-bottom-1px navy-blue-border-right-1px"
                          v-for="(sizes, index) in bodySizes" :key="index">
                        <div class="d-flex justify-content-between items-center ">
                          {{ sizes.name }}
                        </div>
                      </th>
                      <th lass="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                        #
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(target, index) in  targetInputs" :key="index">
                        <td 
                         class="navy-blue-border-bottom-1px navy-blue-border-right-1px"
                         v-if="Number(target.id) !== -1">
                          {{ target.date | laravelDateToSystem }}
                        </td>
                        <td
                          class="navy-blue-border-bottom-1px navy-blue-border-right-1px"
                          v-for="(sizes, index) in target.actual_by_sizes" :key="index">
                          <input v-if="canCheckInsertScope||((Number(target.id) === -1 || isSameDate(target.date)))" 
                          type="number" step="1" :placeholder="$t('following.please_enter_manually')" class="form-control form-control-xs" v-model="sizes.actual">
                          <template v-else>
                            {{ sizes.actual }}
                          </template>
                        </td>
                        <td class="navy-blue-border-bottom-1px navy-blue-border-right-1px">
                          <button-with-icon
                            v-if="canCheckInsertScope||((Number(target.id) === -1 || isSameDate(target.date)) && item.order_expense.expense.suppliers_can_enter_body_sizes)"
                              @onClick="saveOrUpdateInput(index)"
                            :text="Number(target.id) === -1 ? $t('following.save') : $t('following.update')"
                            :icon-name="null"
                            size="sm"
                            :disabled="Number(target.amount) === 0 && is_submitting"
                          ></button-with-icon>
                        </td>
                      
                      </tr>
                    </tbody> 
                  </table>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapMutations, mapGetters} from 'vuex'
import {CREATE_ITEM, GET_ITEM_DETAIL_BY_ID, LOADING, UPDATE_ITEM_BY_ID} from "@/core/services/store/REST.module";
import {LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT} from "@/core/config/constant";
import moment from "moment";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import {GET_ITEMS} from "@/core/services/store/modelhouse/modelhouse.module";

import $ from 'jquery'
import FileInput from "@/assets/components/inputs/FileInput";
import PhotoRecorder from "@/assets/components/recorder/PhotoRecorder";

export default {
  name: "EditFollowingProduction",
  components: {
    DatePickerInput,
    ButtonWithIcon,
    DashboardBox,
    PhotoRecorder,
    FileInput
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
    }),
    enteredTotal(){
      return Number(this.secondQualityInputs.loaded_quantity_supplier) + Number(this.secondQualityInputs.sample_amount_supplier) + Number(this.secondQualityInputs.second_quantity_supplier)+Number(this.secondQualityInputs.part_return_supplier);
    }, 
     enteredTotalAdmin(){
      return Number(this.secondQualityInputs.loaded_quantity_manager) + Number(this.secondQualityInputs.sample_amount_manager) + Number(this.secondQualityInputs.second_quantity_manager)+Number(this.secondQualityInputs.part_return_manager);
    },
    canCheckInsertScope(){

      if(this.isUserGranted('FollowingProduct', ['updateActual'],false,true)){
        return true;
      }else{
        return false;
      }
    }, 
    canCheckSupplierScope(){
      if(this.isUserGranted('Staff', ['supplierCompanyStaff'],false,true)){
        return true;
      }else{
        return false;
      }
    },
    canCheckCustomRolesScope(){      
      let user = this.authUser; 
      let hasId38 = user.roles.some(role => role.id === 38);
      let hasId30 = user.roles.some(role => role.id === 30);
      if(hasId38 || hasId30 ){//Özel Fatura Giriş Sorumlusu,2.Kalite Fatura Onay Süreci
        return true;
      }else{
        return false;
      }
    }, 
    canCheckAdminScope(){
      if(this.isUserGranted('Staff', ['superAdmin'],false,true)){
        return true;
      }else{
        return false;
      }
    },
    // NOTE: Burası her yerden silindi çünkü aynı gün içerisinde olmayınca işlem yapmasına izin vermiyor, bizim amacımız ise aynı günden 36 saat önce ve 36 sonra işlem yapabiliyor olması
    canAddInput(){
      for(let i = 0; i < this.item.order_expense_detail_targets.length; i++){
        const target = this.item.order_expense_detail_targets[i];
        if(moment(target.date, LARAVEL_DATE_FORMAT).isSame(moment(), 'day') && Number(target.id) !== -1){
          return true;
        }
      }
      return false;
    },
    bodySizes(){
      let body_sizes=JSON.parse(this.item.order_size_and_color.body_sizes);
      return body_sizes;
    },
    orderAmount(){
      if(this.item == null) return;
      let total = 0;
      this.item.order_expense_detail_targets.forEach(target => {
        total += Number(target.target);
      })
      return total;

    },
    bodySizesTargets(){
      let dateBodySizesTable
    },
    actualAmountCut(){
      if(this.item == null) return;

      const filteredItems = this.item.order_expense.order.cut_expense_sum_actual.filter(actualItem => {
        return actualItem.color_id === this.item.order_size_and_color.id
        && actualItem.supplier_id === this.item.supplier_company_id;
      }); 
      let totalCut=0;
      filteredItems.forEach(value => {
        if(value !=0){
          totalCut +=value.total
        }
      })
      return totalCut; 
    },
    actualAmount(){
      if(this.item == null) return;
      let total = 0;
      this.item.order_expense_detail_targets.forEach(target => {
        total += Number(target.actual);
      })
      return total;
    }
  },
  
  data() {
    return {
      type: null,
      id: Number(this.$route.params.id),
      item: null,
      body_and_sizes_targets:[],
      is_submitting: false,
      targetInputs:[],      
      document: null,
      secondQualityInputs:{
        loaded_quantity_supplier : null,
        sample_amount_supplier:null,
        second_quantity_supplier:null,
        part_return_supplier:null,
        total_supplier:null,
        loaded_quantity_manager:null,
        sample_amount_manager:null,
        second_quantity_manager:null,
        part_return_manager:null,
        total_manager:null,
        total_cut:null,
      },
      total_cut:0,
    };
  },
  methods: {
    ...mapMutations({}), 
    isSupplierEditFormData(date){ 
      if(date){
        let givenDate = new Date(date);
        let currentDate = new Date();
        let oneWeek = 7 * 24 * 60 * 60 * 1000;
        return (currentDate - givenDate) > oneWeek;
      }
    },
    openUploadDocumentModal() {
      this.$refs["upload-new-waybill-image"].show(); 
    },
    closeUploadDocumentModal() {      
      this.$refs["upload-new-waybill-image"].hide(); 
    },
    cancelDocumentUpload() {
      this.document = null;
      this.closeUploadDocumentModal();
    },
    onDocumentChange() {
      const img = document.getElementById(`following_document`);
      if (img) {
        img.src = URL.createObjectURL(this.document)
      }
    },
    saveOrUpdateInput(index){
      if (!this.isUserGranted('Staff', ['supplierCompanyStaff', 'superAdmin'])) {
        this.sweetAlertError('general.you_have_no_authorized_to_do_this_action');
        return;
      }

      if(this.is_submitting) return;
      this.is_submitting = true;
      this.sweetAlertLoading();
      const current = this.targetInputs[index], self = this;
      let actualBySizes=this.item.order_expense.expense.suppliers_can_enter_body_sizes?current.actual_by_sizes:null;

      this.$store.dispatch(Number(current.id) === -1 ? CREATE_ITEM : UPDATE_ITEM_BY_ID, {
        url: Number(current.id) === -1 ? `api/orders/${this.item.order_expense.order_id}/expenses/${this.item.order_expense.id}/details/${this.id}/production-input` :
            `api/orders/${this.item.order_expense.order_id}/expenses/${this.item.order_expense.id}/details/${this.id}/production-input/${current.id}`,
        contents: {
          date: moment().format(LARAVEL_DATE_FORMAT),
          amount: current.amount,
          order_expense_detail_id: this.id,
          actual_by_sizes:actualBySizes
        },
        showLoading: false,
      }).then(response => {
        self.sweetAlertClose();
        if(response.status){
          self.sweetAlertSuccess(self.$t('general.successfully_saved')).then(() => {
            self.followingItem()
          });

          self.targetInputs[index].id = response.data.id;

        }else{
          self.sweetAlertError(self.$t('general.internal_error'));
        }
        self.is_submitting = false;
      })
    },
    isSameDateWhatOclok(date){
      const givenDate = moment(date, LARAVEL_DATE_FORMAT).endOf('hours').fromNow();
      // const now = moment();
      
      // const hourDiff = now.diff(givenDate, 'hours');
      return givenDate;
    },
    isSameDate(date){
      const givenDate = moment(date, LARAVEL_DATE_FORMAT);
      const now = moment();
      
      const hourDiff = now.diff(givenDate, 'hours');

      if(hourDiff >= 0 && hourDiff <= 36){
        return true;
      }

      return false;
    },
    addNewInput(){
      if (this.isUserGranted('Staff', ['supplierCompanyStaff'])) {
        this.sweetAlertError('general.you_have_no_authorized_to_do_this_action');
        return;
      }

      if(this.item == null) return;
      this.item.order_expense_detail_targets.push({
        id: -1,
        amount: null,
        date: moment().format(LARAVEL_DATE_FORMAT),
      })
    }, 
    saveNewTarget(index){
      this.sweetAlertError('general.you_have_no_authorized_to_do_this_action');
      return;

      if(this.is_submitting) return;
      this.is_submitting = true;
      this.sweetAlertLoading();
      const self = this;
      this.$store.dispatch(CREATE_ITEM, {
        url: `api/orders/${this.item.order_expense.order_id}/expenses/${this.item.order_expense.id}/details/${this.id}/targets`,
        contents: {
          date: this.item.order_expense_detail_targets[index].date.format(LARAVEL_DATE_FORMAT),
          target: this.item.order_expense_detail_targets[index].target
        },
        showLoading: false,
      }).then(response => {
        self.sweetAlertClose();
        if(response.status){
          self.sweetAlertSuccess(self.$t('general.successfully_created'));
          self.followingItem();
        }else{
          self.sweetAlertError(self.$t('general.internal_error'));
        }
        self.is_submitting = false;
      })
    },
    addNewTarget(){
      this.sweetAlertError('general.you_have_no_authorized_to_do_this_action');
      return;

      if(this.item == null) return;
      this.item.order_expense_detail_targets.push({
        "id": -1,
        "order_expense_detail_id": this.id,
        "date": moment(),
        "target": null,
        "deleted_at": null,
        "actual": 0
      })
    },
    followingItem() {
      let self = this;
      this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, {
        url: `api/orders/expenses/details/${this.id}`,
        showLoading: true,
      }).then(response => {
        if(response.status){
          self.item = response.data;
          self.type = Number(response.data.order_expense.expense.type_id);
          self.item.dispatch_locations = self.item.order_expense_detail_dispatch_locations.map(location => {
            return location.dispatch_location.name;
          })
          let results = [];
          self.targetInputs=[]
          if (!self.item) return self.targetInputs=results;
          
          let body_sizes=JSON.parse(self.item.order_size_and_color.body_sizes);      
          
          self.item.order_expense_detail_targets.forEach(item => {
            
            let actualBySizes=[];
            if(item.actual_by_sizes!=null){
              actualBySizes=JSON.parse(item.actual_by_sizes);
            }else{
              Object.entries(body_sizes).forEach(bodySize=>{
                actualBySizes.push(
                  {
                    'body_size_id':bodySize[1].body_size_id,
                    'actual':0,
                    'name':bodySize[1].name,
                    'edit':true
                    
                  }
                )
              })
            }
            let edited=true; //geçmiş kayıtların bu güncellemeden etkilenmeden tabloda girilen adetlerin görünmesi için yapıldı
            if(item.actual>0 && item.actual_by_sizes==null){
              edited=false;
            }
            results.push({
              id: item.id,
              date: item.date,
              amount: item.actual,
              actual_by_sizes:actualBySizes,
              edited:edited
            })
          });
          results.sort((a, b) => {
            // Tarihleri karşılaştırarak küçükten büyüğe sıralama
            return new Date(a.date) - new Date(b.date);
          });
        self.targetInputs= results;
        
        } else{
          self.sweetAlertError(self.$t('general.invalid_request')).then(() => {
            self.$router.go(-1);
          });
        }
      })
    },
    openEntryDataModalTable() {
      this.getSecondQuantity();
      this.$refs["display-detail-modal"].show();
    },
    closeModal() {
      this.$refs["display-detail-modal"].hide();
    },       
    getSecondQuantity(){ 
      let self = this;       
       this.$store
        .dispatch(GET_ITEMS, {
          url: "api/order-expense-detail-second-quality/" + this.id, 
          showLoading: true,
        })
        .then((response) => {
          if (response.status) {
            this.secondQualityInputs.loaded_quantity_supplier = response.data[0].supplier.loaded_quantity;
            this.secondQualityInputs.sample_amount_supplier = response.data[0].supplier.sample_amount;
            this.secondQualityInputs.second_quantity_supplier = response.data[0].supplier.second_quantity;
            this.secondQualityInputs.part_return_supplier = response.data[0].supplier.part_return;  
            this.secondQualityInputs.total_cut = response.data[0].supplier.total_cut;  
            this.total_cut = response.data[0].supplier.total_cut;  

            this.secondQualityInputs.loaded_quantity_manager = response.data[1].manager.loaded_quantity;
            this.secondQualityInputs.sample_amount_manager = response.data[1].manager.sample_amount;
            this.secondQualityInputs.second_quantity_manager = response.data[1].manager.second_quantity;
            this.secondQualityInputs.part_return_manager = response.data[1].manager.part_return; 
          }
        }); 
    },
    saveSecondQuantity(){
      const self = this; 
      let dataForm = this.secondQualityInputs

      let form = new FormData();
      this.createFormDataForList(form, 'second_quality_inputs', dataForm) 
      if (this.document) {
        form.append('document', this.document);
      }

      this.$store.dispatch(CREATE_ITEM, {
        url: `api/order-expense-detail-second-quality/${this.id}/store`,
        acceptPromise: true,
        contents: form,
        showLoading: false,
      }).then(response => {
        if(response.status){
          self.sweetAlertSuccess(self.$t('general.successfully_saved'));
          self.followingItem();
        }else{
          self.sweetAlertError(self.$t('general.internal_error'));
        }
        this.closeModal(); 
      })
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t('following.production_plan'), route: 'following_production.index'},
      {title: this.$t('general.dashboard')}
    ]);

    if(this.id === 0) {
      this.$router.go(-1);
      return;
    }

    this.followingItem();  
  }, 
  watch: {
    'targetInputs': {
      handler(newValue, oldValue) {
        if(this.item && this.item.order_expense.expense.suppliers_can_enter_body_sizes){
          let totalActual=0;
          newValue.forEach((item,index)=>{
            totalActual=0;
            item.actual_by_sizes.forEach(sizes=>{
              totalActual+=Number(sizes.actual)
            })
            if(item.edited){
              this.targetInputs[index].amount=totalActual;
            }
          })
        }
      },
      immediate: true,
      deep: true
    },
    // 'document': {
    //   handler(newValue, oldValue) { 
    //     if(this.canCheckSupplierScope || this.canCheckAdminScope) {
    //       if(this.document) 
    //         this.secondQualityInputs.document = this.document 
    //     }else{
    //       if(this.document)
    //         this.secondQualityInputs.document = this.document
    //     } 
    //   }
    // }
  }
  }

</script>

<style scoped>
.min-w-300px {
  min-width: 300px !important;
}

.min-w-150px {
  min-width: 150px !important;
}
.top_header{
  font-size: 18px;
  font-weight: 7000;
  color: #f2f2f2; 
  background: #000000;
  padding: 5px;  
}
.is-invalid{
  background:#f4acac
}
</style>
